const prevArw = '<button type="button" class="navi navi-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>';
const nextArw = '<button type="button" class="navi navi-next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>';
// var imgurl = '../assets/images/icons/nav-left.png';
const prevArrw = '<button type="button" class="navi navi-prev"></button>';
const nextArrw = '<button type="button" class="navi navi-next"></button>';
// Slider settings
var referenceDetailOptions = {
    slidesToShow: 1,
    dots: true,
    infinite: false,
    speed: 800,
    fade: true,
    dots: true,
    prevArrow: prevArrw,
    nextArrow: nextArrw
}
var referencesOptions = {
    lazyLoad: 'ondemand',
    slidesToShow: 5,
    slidesToScroll: 5,
    prevArrow: prevArw,
    nextArrow: nextArw,
    responsive: [{
        breakpoint: 1200,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
        }
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
        }
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
        }
    },
    {
        breakpoint: 556,
        settings: {
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 2,
        }
    },
    {
        breakpoint: 480,
        settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: '80px',
        }
    }
    ]
}
var testimonialsOptions = {
    lazyLoad: 'ondemand',
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: prevArw,
    nextArrow: nextArw,
    adaptiveHeight: true,
    autoplay: true,
    dots: true,
}

function headerSlider() {
    if ($('.header-slider').length) {
        $('.header-slider').slick(referenceDetailOptions);
    }
}
function referencesSlider() {
    if ($('.references-slider').length) {
        $('.references-slider').slick(referencesOptions);
    }
}

function testimonialSlider() {
    if ($('.testimonials-slider').length) {
        $('.testimonials-slider').slick(testimonialsOptions);
    }
}



module.exports.testimonialSlider = testimonialSlider;
module.exports.headerSlider = headerSlider;
module.exports.referencesSlider = referencesSlider;