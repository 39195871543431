var Utility = {
    definePatternHeight: function () {
        var height = document.body.clientHeight;
        var bodyHeight = height;
        $('.pattern-overlay').css('height', bodyHeight);
    },
    defineBackToTop: function () {
        // back to top button on scroll
        var s = $(window).scrollTop(),
            d = $(document).height(),
            c = $(window).height();

        var scrollPercent = Math.trunc((s / (d - c)) * 100);
        // If Scroll is > 50 show back_to_top btn, 
        if (scrollPercent >= 50) {
            setTimeout(function () {
                $('#back_to_top').removeClass('animate-out');
                $('#back_to_top').addClass('animate-in');
            }, 500)
        }
        // if scroll is < 10 hide back_to_top btn
        if (scrollPercent <= 10) {
            setTimeout(function () {
                $('#back_to_top').removeClass('animate-in');
                $('#back_to_top').addClass('animate-out');
            }, 500)
        }
    },
    setEqualHeight: function (element) {
        var maxContentHeight = 0;
        $(element).each(function () {
            if ($(this).height() > maxContentHeight) {
                maxContentHeight = $(this).height();
            }
        });
        $(element).height(maxContentHeight);
    }
}

module.exports = Utility;