var slick = require('../vendor/slick.min.js');
require('jquery-ui');
var Slider = require('./sliders.js');
var Utility = require('./utilities.js');
var AOS = require('aos');

$(document).ready(function () {
    Utility.definePatternHeight();
    AOS.init();
    Slider.headerSlider();
    Slider.referencesSlider();
    Slider.testimonialSlider();

    //
    // Social share popup activation
    //
    $(document).on('click', '.social-popup-trigger', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var targetEl = $(this).parent().find('.social-popup');
        targetEl.toggleClass('active');
        if (targetEl.hasClass('on')) {
            targetEl.removeClass('on');
        } else {
            setTimeout(function () {
                targetEl.addClass('on');
            }, 100);
        }
    });

    $(document).on('click', '#share-icon', function (e) {
        e.preventDefault();
        e.stopPropagation();
        // console.log('test click');
        var tarEl = $(this).parent().find('.social-icon-popup');
        tarEl.toggleClass('active');
        if (tarEl.hasClass('on')) {
            tarEl.removeClass('on');
        } else {
            setTimeout(function () {
                tarEl.addClass('on');
            }, 100)
        }
    });

    //
    // Anchor link scrollTop animation
    //
    $(document).on('click', '.anchor', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var id = $(this).attr('href');
        if ($(id).length) {
            $('html, body').animate({
                scrollTop: $(id).offset().top - 90
            });
        }
    });

    //
    // Comparison table responsive view
    //
    var products = $('.comparison-table .col-product');
    var tabs = $('#productTabs .nav-link');
    var window_correction = 17;

    function setActiveProduct() {
        if ($(window).width() < 769 - window_correction) {
            products.addClass('tab-pane fade');
            products.removeClass('show active');
            products.first().addClass('show active');
            tabs.removeClass('show active');
            tabs.first().addClass('show active');
        } else {
            products.removeClass('tab-pane fade show active');
            tabs.removeClass('show active');
        }
    }

    setActiveProduct();

    //
    // Set equal row height
    //

    function setEqualRowHeight(el, el2) {
        if ($(window).width() > 768) {
            var h;
            var arr = [];
            $(el).each(function (i) {
                h = $(this).height();
                arr.push(h);
            });

            $(el2).each(function () {
                $(this).find('li').each(function (i) {
                    $(this).height(arr[i]);
                });
            });
        }
    }

    setEqualRowHeight('.col-feature li', '.col-product');

    //
    // Navigation animation
    //
    var menu = $(document).find('[data-menu]').attr('data-menu');
    $(document).on('click', '[data-menu]', function (e) {
        e.preventDefault();
        $(menu).toggleClass('open');
        $(menu).find('.main-navigation').toggleClass('show');
        $('.nav-backdrop').toggleClass('show');
        $('body').toggleClass('menu-is-open');
    });

    //
    // Cookie set and link action
    //
    var cookie_name = 'seenCookieMessage';
    //CookieMonster.eraseCookie(cookie_name);
    /*$(document).on('click', '#btnCookie', function(e)  {
        e.preventDefault();
        e.stopPropagation();
        CookieMonster.setCookie(cookie_name, 'yes', 60);
        $('.cookie-component').addClass('close-cookie');
    });*/

    //
    // Close popups(...) when clicked outside of target element
    //
    $(document).on('click', function () {
        $('.social-popup').removeClass('active on');
    });

    //  Back to top anchor
    var bktt = $('#back_to_top');
    bktt.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 800);
    });

    // Back to top animation appearance on scroll
    $('body').on('scroll', function (e) {
        console.log(e, $(this));
        if ($(this).scrollTop > 400) {
            bktt.addClass('animate-in');
        } else {
            bktt.removeClass('animate-in');
        }
    });

    // All window resize functions go here
    if ($(window).width() > 769 - window_correction) {
        $(window).resize(function () {
            setActiveProduct();
            setEqualRowHeight('.col-feature li', '.col-product');
        });
    }

    // On tab select animation (the selected tab animates)
    $('#productTabs li').on('click', function () {
        var scroll = 0;
        if ($(this).offset().left > $(window).width() / 2) {
            scroll = $('#productTabs').scrollLeft() + $(this).width();
        } else {
            scroll = $('#productTabs').scrollLeft() - $(this).width();
        }

        $(this).parents('.nav-tabs').animate({
            scrollLeft: scroll
        }, 800);
    });



    // Definiranje visine contenta
    Utility.setEqualHeight('.solution-desc');
    Utility.setEqualHeight('.solution-title');
    Utility.setEqualHeight('.service-desc');
    Utility.setEqualHeight('.service-title');
    Utility.setEqualHeight('.blog-title');
    Utility.setEqualHeight('.blog-desc');
    Utility.setEqualHeight('.blog-img-wrapper');
    // Kraj Definiranja visine contenta

    // REFERENCE  TAB SWITCHER
    $('.ref-btn').on('click', function (e) {
        e.preventDefault();
        var thisClass = $(this).attr('id');
        $('.all-btn').removeClass('active');
        $('.ref-btn').removeClass('active');
        $(this).addClass('active');
        $('.reference-item').each(function () {
            if ($($(this)).hasClass(thisClass)) {
                $(this).show(400);
            } else {
                $(this).hide(400);
            }
        })
        setTimeout(function(){AOS.init()}, 500);
        
    });

    $('.all-btn').on('click', function (e) {
        e.preventDefault();
        $('.ref-btn').removeClass('active');
        $(this).addClass('active');
        $('.reference-item').each(function () {
            $(this).show(400);
        })
        setTimeout(function(){AOS.init()}, 500);
    });

    var fullUrl = window.location.href;
    var referenceHash = window.location.hash;
    var referenceId = referenceHash.slice(1);
    $('.ref-btn').each(function(){
        if($(this).attr('id') === referenceId){
            $(this).trigger('click');
        } 
    })    

    var pageContactHash = window.location.hash;
    var pageContantName = pageContactHash.slice(1);

    if(pageContactHash){
        $('#contact_subject').val(decodeURI(pageContantName));
    }
});

$(document).scroll(function () {
    Utility.definePatternHeight()
});

$(window).on('scroll', Utility.defineBackToTop);

// YouTube custom play button
// Fades out cover image and button on click and starts video that is behind button and/or image
$(document).on('click', '#video-play-lazy', function (e) {
    e.preventDefault();
    var _this = $(this);
    $(this).fadeOut('normal');
    $(this).parent().find('img').fadeOut('normal');
    var iframe = document.createElement('iframe');
    var embed = $(this).parent().data('embed');
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "");
    iframe.setAttribute("src", "https://www.youtube.com/embed/" + embed + "?enablejsapi=1&version=3&playerapiid=ytplayer");
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    $(this).parent().append(iframe);
    setTimeout(function () {
        _this.parent().find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
    }, 500);
});
$(document).on('click', '#video-play-normal', function (e) {
    e.preventDefault();
    var _this = $(this);
    $(this).fadeOut('normal');
    $(this).parent().find('img').fadeOut('normal');
    $(this).parent().find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');

});

// pause video on click
$(document).on('click', '.navi', function (e) {
    e.preventDefault();
    for (var i = 0; i < $(this).parent().find('iframe').length; i++) {
        $(this).parent().find('iframe')[i].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    }
});

$(function () {
    $(document).scroll(function () {
        var $nav = $(".header-container");
        var $header = $('.header-container');
        $nav.toggleClass('scrolled', $(this).scrollTop() > $header.height());
    });
    if($('.header-container').offset().top > $('.header-container').height()){
        $('.header-container').addClass('scrolled');
    }
});

$(function(){
    $('.nav-item').each(function(){
        var windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var navItemWidth = parseInt($('.nav-item').css('width'));
        var navItemPLeft = parseInt($('.nav-item').css('padding-left'));
        var navItemPRight = parseInt($('.nav-item').css('padding-right'));
        var navItemPadding = navItemPLeft + navItemPRight;
        $('.nav-link').each(function(){
  
        var navLinkWidth = parseInt($(this).css('width'));
        var remainingWidth = navItemWidth -  (navItemPadding + navLinkWidth + 15);
        $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
        if(windowWidth < 370){
            var navLinkWidth = parseInt($(this).css('width'));
            var remainingWidth = navItemWidth -  (navItemPadding + navLinkWidth + 20);
            $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
        }
        if(windowWidth < 290){
            var navLinkWidth = parseInt($(this).css('width'));
            var remainingWidth = navItemWidth -  (navItemPadding + navLinkWidth + 25);
            $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
        }
        if(windowWidth < 237){
            var navLinkWidth = parseInt($(this).css('width'));
            var remainingWidth = navItemWidth -  (navItemPadding + navLinkWidth + 30);
            $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
        }

        })
    });
})